import {
    useTranslate,
    IResourceComponentsProps,
    useMany, HttpError, CrudFilters, getDefaultFilter, Option,
} from '@pankod/refine-core';
import {
    List,
    Table,
    TextField,
    useTable,
    Icons,
    getDefaultSortOrder,
    DateField,
    Space,
    EditButton,
    DeleteButton,
    useSelect,
    TagField,
    FilterDropdown,
    Select,
    ShowButton, Col, Row, Form, Input, Button, Card, DatePicker, Show,
} from '@pankod/refine-antd';
import {IProductOrder} from "../../interfaces";
import React, {useMemo, useState} from "react";
import dayjs from "dayjs";

export const OdpList: React.FC<IResourceComponentsProps> = () => {
    // STATO ODP: boposPlanned, boposReleased, boposClosed
    const {tableProps, sorter, searchFormProps, filters} = useTable<IProductOrder>({
        initialSorter: [
            {
                field: "DocumentNumber",
                order: "asc",
            },
        ],
        onSearch: (params: any) => {
            console.log(params);
            // setCategory(params.category);
            const filters: CrudFilters = [];
            const {ProductDescription, Warehouse, PostingDate, ProductionOrderStatus, DocumentNumber} = params;
            filters.push(
                {
                    field: "ProductDescription",
                    operator: "contains",
                    value: ProductDescription,
                },
                {
                    field: "Warehouse",
                    operator: "eq",
                    value: Warehouse,
                },
                {
                    field: "DocumentNumber",
                    operator: "eq",
                    value: DocumentNumber,
                },
            );
            filters.push(
                {
                    field: "PostingDate",
                    operator: "gte",
                    value: PostingDate
                        ? PostingDate[0].startOf("day").toISOString()
                        : undefined,
                },
                {
                    field: "PostingDate",
                    operator: "lte",
                    value: PostingDate
                        ? PostingDate[1].endOf("day").toISOString()
                        : undefined,
                },
            );
            filters.push({
                field: "ProductionOrderStatus",
                operator: "in",
                value: ProductionOrderStatus,
            });
            return filters;
        },
    });

    const [title, setTitle] = useState('');

    const { RangePicker } = DatePicker;

    const PostingDate = useMemo(() => {
        const start = getDefaultFilter("PostingDate", filters, "gte");
        const end = getDefaultFilter("PostingDate", filters, "lte");

        const startFrom = dayjs(start);
        const endAt = dayjs(end);

        if (start && end) {
            return [startFrom, endAt];
        }
        return undefined;
    }, [filters]);

    return (
        <Row gutter={[16, 16]}>
            <Col lg={6} xs={24}>
                <Card bordered={false} title='FILTRI'>
                    <Form layout="vertical" {...searchFormProps}>
                        <Form.Item label="Numero Documento" name="DocumentNumber">
                            <Input
                                placeholder="Numero Documento"
                                prefix={<Icons.SearchOutlined/>}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Descrizione Prodotto" name="ProductDescription">
                            <Input
                                placeholder="Descrizione Prodotto"
                                prefix={<Icons.SearchOutlined/>}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Magazzino" name="Warehouse">
                            <Input
                                placeholder="Magazzino"
                                prefix={<Icons.SearchOutlined/>}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item label="Stato ODP" name="ProductionOrderStatus">
                            <Select
                                mode="multiple"
                                placeholder="Seleziona Stato"
                                allowClear
                                options={[
                                    {
                                        label: "Pianificati",
                                        value: "boposPlanned",
                                    }, {
                                        label: "Rilasciati",
                                        value: "boposReleased",
                                    }, {
                                        label: "Chiusi",
                                        value: "boposClosed",
                                    }, {
                                        label: "Cancellato",
                                        value: "boposCancelled",
                                    },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Data Scadenza'
                            name="PostingDate"
                        >
                            <RangePicker style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">
                                Filtra
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>

            </Col>
            <Col lg={18} xs={24}>
                <Card bordered={false} title='Ordini di Produzione'>
                <List title=''>
                    <Table {...tableProps} rowKey="AbsoluteEntry" >
                        <Table.Column
                            dataIndex="DocumentNumber"
                            key="DocumentNumber"
                            title='Num. Documento'
                            sorter
                            defaultSortOrder={getDefaultSortOrder("DocumentNumber", sorter)}
                            render={(value) => <TextField value={value}/>}
                        />
                        <Table.Column
                            dataIndex="PostingDate"
                            key="Data Scadenza"
                            title='PostingDate'
                            sorter
                            defaultSortOrder={getDefaultSortOrder("PostingDate", sorter)}
                            render={(value) => <DateField format="DD-MM-YYYY" value={value} />}
                        />
                        <Table.Column
                            dataIndex="ProductionOrderStatus"
                            key="ProductionOrderStatus"
                            title="Stato"
                            sorter={{multiple: 2}}
                            render={(value) => <TextField value={value}/>}
                        />
                        <Table.Column
                            dataIndex="Warehouse"
                            key="Warehouse"
                            title="Magazzino"
                            sorter={{multiple: 2}}
                            defaultSortOrder={getDefaultSortOrder("DocumentNumber", sorter)}
                            render={(value) => <TagField value={value}/>}
                        />
                        <Table.Column
                            dataIndex="ProductDescription"
                            key="ProductDescription"
                            title="Articolo"
                            sorter={{multiple: 3}}
                            defaultSortOrder={getDefaultSortOrder("ProductDescription", sorter)}
                            render={(value) => <TextField value={value}/>}
                        />
                        <Table.Column<IProductOrder>
                            dataIndex="Action"
                            key="action"
                            title="Azioni"
                            render={(_text, record): React.ReactNode => {
                                return (
                                    <Space>
                                        <ShowButton icon={<Icons.SearchOutlined />}
                                            recordItemId={record.AbsoluteEntry}
                                        />
                                    </Space>
                                );
                            }}
                        />
                    </Table>
                </List>
                </Card>
            </Col>
        </Row>

    );
};
