import {
    IResourceComponentsProps,
    CrudFilters, getDefaultFilter, useCustom, useNotification, useList, useExport
} from '@pankod/refine-core';
import {
    List,
    Table,
    Select,
    Col, Row, ExportButton, Input, useSelect, Button, DateField,
} from '@pankod/refine-antd'
import React, {useEffect, useState} from 'react';
import { CSVLink, CSVDownload } from 'react-csv';
import FileSaver from 'file-saver';
import {axiosInstance} from '../../provider';
import {API_URL} from '../../constants';
import dayjs from 'dayjs';

export const AnalisiList: React.FC<IResourceComponentsProps> = () => {
    const [columns, setColumns] = useState<any>([]);
    const [entity, setEntity] = useState('');
    const [fileName, setFileName] = useState('');
    const [analisiData, setAnalisiData] = useState<any>([]);
    const [filterData, setFilterData] = useState<any>([]);
    const data = useList<any>({
        resource: "analisi/" + entity,
        queryOptions: {
            enabled: false
        }
    });

    // const getFile = useCustom({
    //     url: "getFile/SBO_CTGROUP/Attachments/" + fileName,
    //     method: "get",
    //     queryOptions: {
    //         enabled: false
    //     },
    //
    // })
    const downloadFile = (files: any) => {
        // console.log(files);
        setFileName(files);
        // console.log("XXXX", API_URL + "/getFile/SBO_CTGROUP/Attachments/" + files)
        axiosInstance.get(API_URL + "/api/getFileClient/SBO_CTGROUP/Attachments/" + files, {
            responseType: 'blob', // had to add this one here
        }).then( res => {
            // console.log(res);
            const file = new Blob([res.data], { type: "application/pdf" });
            FileSaver.saveAs(file, files);
            // window.open(window.URL.createObjectURL(file))
        })
        // setTimeout(() => {
        //     getFile.refetch().then( (res: any) => {
        //         console.log('xxxx', res);
        //         const file = new Blob([res.data], { type: "application/pdf" });
        //         FileSaver.saveAs(file, files);
        //         // window.open(window.URL.createObjectURL(file))
        //     });
        // }, 500)
    }

    const onSelect = (e: any) => {
        console.log(e);
        setEntity(e);
        setAnalisiData([]);
        setFilterData([]);
        setColumns(null);
        setTimeout(() => {
            data.refetch().then( res => {
                if (res && res.data?.data && res.data.data.length > 0) {
                    console.log(res.data);
                    let col: any = [];
                    for (let c of Object.keys(res.data.data[0])) {
                        // console.log(c);
                        if (c === 'Attachment') {
                            col.push({
                                key: Math.random(), dataIndex: c, title: c, render: (value: any) => {
                                    if (value && value.length > 0) {
                                        return (
                                            value.map((x: any) =>
                                                <div style={{cursor: 'pointer'}} onClick={() => downloadFile(x)}>{x}</div>
                                            )

                                        )
                                    }
                                }
                            });
                        } else if(c === 'DataStartFase') {
                            col.push({
                                key: Math.random(), dataIndex: c, title: c, render: (value: any) => {
                                    return (<DateField value={value} format={'DD-MM-YYYY'} />)
                                }
                            });
                        } else if(c === 'DataStopFase') {
                            col.push({
                                key: Math.random(), dataIndex: c, title: c, render: (value: any) => {
                                        return (<DateField value={value} format={'DD-MM-YYYY'}/>)
                                }
                            });
                        } else if(c === 'Lancio OdP Date') {
                            col.push({
                                key: Math.random(), dataIndex: c, title: c, render: (value: any) => {
                                    return (<DateField value={value} format={'DD-MM-YYYY'}/>)
                                }
                            });
                        } else {
                            col.push({key: Math.random(), dataIndex: c, title: c });
                        }

                    }
                    //console.log(col);
                    setColumns(col);
                    for (let r of res.data.data) {
                        if (r['Attachment'] && r['Attachment'].length > 0) {
                            // console.log(r['Attachment']);
                            for (let a of r['Attachment']) {
                                // console.log(a);
                            }
                        }
                        if (r['DataStartFase']) {
                            // console.log(r['Attachment']);
                            r['DataStartFase'] = dayjs(r['DataStartFase']).format('DD-MM-YYYY')
                        }
                        if (r['DataStopFase']) {
                            r['DataStopFase'] = dayjs(r['DataStopFase']).format('DD-MM-YYYY')

                        }
                        if (r['DocDate']) {
                            r['DocDate'] = dayjs(r['DocDate']).format('DD-MM-YYYY')
                        }
                        if (r['ShipDate']) {
                            r['ShipDate'] = dayjs(r['ShipDate']).format('DD-MM-YYYY')
                        }
                        if (r['Lancio OdP Date']) {
                            r['Lancio OdP Date'] = dayjs(r['Lancio OdP Date']).format('DD-MM-YYYY')
                        }
                    }
                    setAnalisiData(res.data.data);
                }
            });
        }, 1000)
    }

    const search = (value: any) => {
        setTimeout(() => {
            const filterTable: any = analisiData.filter((o: any) =>
                Object.keys(o).some(k =>
                    String(o[k])
                        .toLowerCase()
                        .includes(value.target.value.toLowerCase())
                )
            );
            setFilterData(filterTable);
        }, 800)
    };
    const onClear = () => {
        setEntity('none');
        setTimeout(() => {
            data.refetch().then( res => {
                // console.log(res);
                setColumns([]);
                setAnalisiData([])
            });
        }, 1000)
    }

    const {selectProps, queryResult} = useSelect({
        resource: 'analisiList'
    })


    return (
        <>
                        <List title='Analisi'
                              headerProps={{
                                  extra: [
                                      <Select
                                          style={{ width: 200}}
                                          allowClear={true}
                                          placeholder="Seleziona Analisi..."
                                          onSelect={(e) => onSelect(e)}
                                          onClear={onClear}
                                      >
                                          {queryResult.data?.data.map((o: any) =>
                                                  <Select.Option key={o.value} value={o.value}>
                                                      {o.label}
                                                  </Select.Option>
                                          )}
                                      </Select>,
                                      <div>
                                          <CSVLink data={analisiData} separator={";"} filename={entity + '.csv'}>
                                              <Button>Export</Button>
                                          </CSVLink>
                                      </div>
                                  ],
                              }}
                              headerButtons={
                                <Row>
                                    <Col style={{ marginRight: 20}}>

                                    </Col>
                                </Row>
                        }>
                            <Input
                                style={{ border: "2px solid red", margin: "0 0 10px 0" }}
                                placeholder="Cerca..."
                                onInput={search}
                            />
                            <Table dataSource={(filterData && filterData.length === 0) ? analisiData : filterData}
                                   rowKey="id"
                                   scroll={{x: 500}}
                                   columns={columns}/>
                        </List>
        </>
    );
};
