import {AuthProvider} from '@pankod/refine-core';
import axios from 'axios';
import {API_URL} from "./constants";

export const TOKEN_KEY = 'refine-auth';
export const axiosInstance = axios.create();

export const authProvider: AuthProvider = {
    login: async ({username, password}) => {
        console.log(username, password);


        const response = await axiosInstance.get(API_URL + '/api/loginFrontend?pin=' + password, {
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        });
        console.log(response);

        if (password && response.status === 200 && response.data.success) {
            // axiosInstance.defaults.withCredentials = true;
            axiosInstance.defaults.baseURL = API_URL + "/sap/"
            localStorage.setItem(TOKEN_KEY, 'OK');
            return Promise.resolve();
        }
        return Promise.reject(new Error(`password: ${password}`));
    },
    logout: () => {
        localStorage.removeItem(TOKEN_KEY);
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token && token === 'OK') {
            // const axiosConfig: any = {
            //     headers: {
            //         'content-Type': 'application/json',
            //         "Accept": "/",
            //         "Cache-Control": "no-cache",
            //         "Cookie": document.cookie
            //     },
            //     credentials: "same-origin"
            // };
            // axiosInstance.defaults.headers.common = axiosConfig;
            // axiosInstance.defaults.withCredentials = true;
            return Promise.resolve();
        }
        return Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
            return Promise.reject();
        }

        return Promise.resolve({
            id: 1,
        });
    },
};
