import React from "react";
import {
    useForgotPassword,
    useRouterContext,
    useTranslate
} from "@pankod/refine-core";
import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button, Modal,
} from "@pankod/refine-antd";
import "./styles.css";
import {IForgotPasswordFormTypes} from "../../interfaces";


const {Text, Title} = Typography;


export const ForgotPassword: React.FC = () => {
    const translate = useTranslate();
    const {Link} = useRouterContext();

    const {mutate: forgotPassword, isLoading} =
        useForgotPassword<IForgotPasswordFormTypes>();

    const onSubmit = (values: IForgotPasswordFormTypes) => {
        forgotPassword(values, {
            onSuccess: () => {
                Modal.success({
                    content: translate('pages.forgotPassword.success', 'We have sent you an email with instructions for resetting your password.'),
                });
            }
        });
    };

    const CardTitle = (
        <Title level={3} className="title">
            {translate('pages.forgotPassword.title', 'Forgot Password')}
        </Title>
    );


    return (
        <AntdLayout className="layout">
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    <div className="container">
                        <div className="imageContainer">
                            <img width={200} src="./logo200.png" alt="Logo"/>
                        </div>
                        <Card title={CardTitle} headStyle={{borderBottom: 0}}>
                            <Form<IForgotPasswordFormTypes>
                                layout="vertical"
                                onFinish={(values) => onSubmit(values)}
                                requiredMark={false}
                            >
                                <Form.Item
                                    name="email"
                                    label={translate(
                                        "pages.forgotPassword.fields.email",
                                        "Email",
                                    )}
                                    rules={[
                                        {required: true},
                                        {
                                            type: "email",
                                            message: translate(
                                                "pages.forgotPassword.errors.validEmail",
                                                "Invalid email address",
                                            ),
                                        },
                                    ]}
                                >
                                    <Input
                                        type="email"
                                        size="large"
                                        placeholder={translate(
                                            "pages.forgotPassword.fields.email",
                                            "Email",
                                        )}
                                    />
                                </Form.Item>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "12px",
                                    }}
                                >
                                    {(
                                        <Text
                                            style={{
                                                fontSize: 12,
                                                marginLeft: "auto",
                                            }}
                                        >
                                            {translate(
                                                "pages.register.buttons.haveAccount",
                                                "Have an account? ",
                                            )}{" "}
                                            <Link
                                                style={{
                                                    fontWeight: "bold",
                                                }}
                                                to="/login"
                                            >
                                                {translate("pages.login.signin", "Sign in")}
                                            </Link>
                                        </Text>
                                    )}
                                </div>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        size="large"
                                        htmlType="submit"
                                        loading={isLoading}
                                        block
                                    >
                                        {translate(
                                            "pages.forgotPassword.buttons.submit",
                                            "Send reset instructions",
                                        )}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
