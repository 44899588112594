export const weekScaleTemplate = (a, b) => {
    return `${a.getMonth()} - ${b.getMonth()}`;
};

export const dayStyle = (a) => {
    const day = a.getDay() === 5 || a.getDay() === 6;
    return day ? "day" : "";
};

export const complexScales = [
    // { unit: "year", step: 1, format: "yyyy" },
    { unit: "month", step: 2, format: "MMMM yyy" },
    { unit: "week", step: 1, format: "w" },
    { unit: "day", step: 1, format: "d", css: dayStyle },
];

export const simpleColumns = [
    { name: "text", label: "Task name", width: "100%" },
];

export function getData(prefix, maxSize, maxYears) {
    maxYears = maxYears || 100;
    maxSize = maxSize || 50;
    prefix = prefix || "";
    const tasks = [];
    tasks.push(
        {
            id: '1',
            start: new Date(2023, 3, 2),
            end: new Date(2023, 3, 3),
            name: prefix + 'ODP + FASE',
            progress: 50,
            dependencies: ['0'],
            type: 'task',
        },
        {
            id: '2',
            start: new Date(2023, 3, 2),
            end: new Date(2023, 3, 4),
            name: prefix + 'ODP + FASE',
            progress: 10,
            dependencies: ['0'],
            type: 'task',
            isDisabled: true,
            styles: {progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d'},
        },
        {
            id: '3',
            start: new Date(2023, 3, 6),
            end: new Date(2023, 3, 10),
            name: prefix + 'ODP + FASE',
            progress: 80,
            dependencies: ['2'],
            type: 'task',
        }
    );

    const links = [
        // { id: 1, source: 3, target: 4, type: 0 },
        // { id: 2, source: 1, target: 2, type: 0 },
        // { id: 21, source: 8, target: 1, type: 1 },
        // { id: 22, source: 1, target: 6, type: 1 },
        // { id: 23, source: 1, target: 3, type: 1 },
        // { id: 24, source: 1, target: 13, type: 1 },
        // { id: 25, source: 1, target: 14, type: 1 },
        // { id: 26, source: 1, target: 15, type: 1 },
        // { id: 27, source: 1, target: 16, type: 1 },
        // { id: 28, source: 1, target: 14, type: 1 },
        // { id: 3, source: 5, target: 6, type: 3 },
        // { id: 4, source: 8, target: 6, type: 1 },
    ];

    const scales = [
        { unit: "month", step: 1, format: "MMMM yyy" },
        { unit: "day", step: 1, format: "d", css: dayStyle },
    ];

    const columns = [
        { name: "text", label: "Task name", width: "100%" },
        { name: "start", label: "Start time", align: "center" },
        { name: "duration", label: "Duration", width: "70px", align: "center" },
        { name: "add-task", label: "", width: "50px", align: "center" },
    ];

    return { tasks, links, scales, columns };
}
