import React from "react";
import {
    Row,
    Col,
    Card,
    Typography,
    Space, Button,
} from "@pankod/refine-antd";
// import {TotaleNotificheContext} from "../totaleNotificheProvider";

const {Text} = Typography;

export const DashboardPage: React.FC = () => {

    return (
        <div></div>
        // <Row gutter={24}>
        //     <Col span={12}>
        //
        //         <Card
        //             title="Ethereum Public ID"
        //             style={{height: "150px", borderRadius: "15px"}}
        //             headStyle={{textAlign: "center"}}
        //         >
        //             <Space align="center" direction="horizontal">
        //                 <Text>fdsfdfd</Text>
        //             </Space>
        //         </Card>
        //     </Col>
        //     <Col span={12}>
        //         <Card
        //             title="Account Balance"
        //             style={{height: "150px", borderRadius: "15px"}}
        //             headStyle={{textAlign: "center"}}
        //         >
        //             <Text>{`Ether`}</Text>
        //         </Card>
        //     </Col>
        //
        //     <Col span={12}>
        //
        //     </Col>
        // </Row>
    );
};
