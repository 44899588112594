import {IResourceComponentsProps} from '@pankod/refine-core';
// @ts-ignore
import {DisplayOption, EventOption, Gantt, StylingOption, Task, ViewMode} from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import React, {useEffect, useState} from 'react';
import {getData} from 'common/data';
import axios from 'axios';
import {Spin} from '@pankod/refine-antd';
import dayjs from 'dayjs';

const {tasks, links, columns} = getData();

export const GanttList: React.FC<IResourceComponentsProps> = () => {

    const [tasks, setTasks] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        async function getData() {
            const response = await axios.get('https://lisa.gruppoct.com/gnt/aggiornaGanttDaDocLink?periodoSchedulazione=23.03 Marzo&isWeb=1');
            const data = await response.data;
            data.map(( d: any) => {
                d.start = new Date(d.start);
                d.end = new Date(d.end);
            })
            setLoading(false);
            setTasks(data);
        }
        getData();
    }, [])

    return (<>
            { loading &&
                <div style={{
                    width: '100%',
                    height: window.innerHeight,
                    marginTop: 300,
                    textAlign: 'center'
                }}>
                    <Spin spinning={loading} size={'large'}/>
                </div>
            }
            { !loading &&
            <div style={{
                position: 'fixed',
                overflowY: 'scroll',
                overflowX: 'scroll',
                height: window.innerHeight - 130,
                width: window.innerWidth - 230
            }}>
                { tasks && tasks.length > 0 &&
                <Gantt
                    headerHeight={50}
                    rowHeight={30}
                    fontSize={'10px'}
                    columnWidth={50}
                    tasks={tasks}
                    viewMode={ViewMode.Day}
                    todayColor={'green'}
                    locale={'it'}
                    TaskListHeader={(props, context) => {
                        return(<div style={{
                            height: 50,
                            display: 'table-row',
                            textOverflow: 'ellipsis',
                            boxSizing: 'border-box'
                        }}>
                            <div style={{
                                height: 50,
                                verticalAlign: 'middle',
                                display: 'table-cell',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                minWidth: 155
                            }} >ODV</div>
                            <div style={{
                                height: 50,
                                verticalAlign: 'middle',
                                display: 'table-cell',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                minWidth: 155
                            }}>Rif. Articolo</div>
                            <div style={{
                                height: 50,
                                verticalAlign: 'middle',
                                display: 'table-cell',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                minWidth: 155
                            }}>Data Scadenza</div>
                        </div>)
                    }}
                    TaskListTable={(props, context) => {
                        console.log(props, context);
                        const head = props.tasks.map((x: any, index) =>
                            <>
                                <div key={index} style={{
                                    height: 30,
                                    display: 'table-row',
                                    textOverflow: 'ellipsis',
                                    boxSizing: 'border-box'
                                }}>
                                    <div style={{
                                        height: 30,
                                        verticalAlign: 'middle',
                                        display: 'table-cell',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        minWidth: 155
                                    }} >{x.numODV}</div>
                                    <div style={{
                                        height: 30,
                                        verticalAlign: 'middle',
                                        display: 'table-cell',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        minWidth: 155
                                    }}>{dayjs(x.codArtCli).format('DD-MM-YYYY')}</div>
                                    <div style={{
                                        height: 30,
                                        verticalAlign: 'middle',
                                        display: 'table-cell',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        minWidth: 155
                                    }}>{dayjs(x.dataScadenza).format('DD-MM-YYYY')}</div>
                                </div>
                            </>
                        )
                        return <header>{head}</header>
                        }}
                />
                }
            </div>
            }
        </>
    );

};
