import React from 'react';

import { Refine } from '@pankod/refine-core';
import {
  ReadyPage,
  ErrorComponent,
} from '@pankod/refine-antd';
import '@pankod/refine-antd/dist/reset.css';
import 'react-toastify/dist/ReactToastify.css';
// import dataProvider from '@pankod/refine-simple-rest';
import routerProvider from '@pankod/refine-react-router-v6';
import { useTranslation } from 'react-i18next';
import { RefineKbarProvider } from '@pankod/refine-kbar';
import { ColorModeContextProvider } from 'contexts';
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from 'components/layout';
import { OdpList, OdpShow } from 'pages/odp';
import {authProvider, axiosInstance} from './authProvider';
import {API_URL} from "./constants";
import {ForgotPassword} from "./pages/login/forgotPassword";
import {ResetPassword} from "./pages/login/resetPassword";
import {Login} from "./pages/login/login";
import {DashboardPage} from "./pages/dashboard";
import {myDataProvider} from "./provider/provider";
import {ItemList} from './pages/items';
import {ZZlabelList} from './pages/zzlabel';
import {AnalisiList} from './pages/analisi';
import {ToastContainer} from 'react-toastify';
import {notificationProvider} from './notificationProvider';
import {BibbiaList} from './pages/bibbia';
import {GanttList} from './pages/gantt';


function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <ColorModeContextProvider>
      <RefineKbarProvider>
          <ToastContainer />
        <Refine
          dataProvider={myDataProvider(API_URL + '/api', axiosInstance)}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          DashboardPage={DashboardPage}
          catchAll={<ErrorComponent />}
          Title={Title}
          Header={Header}
          Sider={Sider}
          Footer={Footer}
          Layout={Layout}
          OffLayoutArea={OffLayoutArea}
          authProvider={authProvider}
          LoginPage={Login}
          i18nProvider={i18nProvider}
          routerProvider={{
              ...routerProvider,
              routes: [
                  { path: "/forgot-password", element: <ForgotPassword  /> },
                  { path: "/reset-password", element: <ResetPassword /> },
              ],
          }}
          resources={[
              {
                  name: 'proxy/ProductionOrders',
                  options: { label: 'Ordini di Produzione'},
                  list: OdpList,
                  // create: PostCreate,
                  // edit: OdpEdit,
                  show: OdpShow,
              },
              {
                  name: 'proxy/Items',
                  options: { label: 'Articoli'},
                  list: ItemList,
                  // create: PostCreate,
                  // edit: PostEdit,
                  // show: PostShow,
              },
              {
                  name: 'zzlabel',
                  options: { label: 'ZZ Label'},
                  list: ZZlabelList,
                  // create: PostCreate,
                  // edit: PostEdit,
                  // show: PostShow,
              },
              {
                  name: 'analisi',
                  options: { label: 'Analisi'},
                  list: AnalisiList,
                  // create: PostCreate,
                  // edit: PostEdit,
                  // show: PostShow,
              },
              {
                  name: 'gantt',
                  options: { label: 'Gantt'},
                  list: GanttList,
                  // create: PostCreate,
                  // edit: PostEdit,
                  // show: PostShow,
              },
              {
                  name: 'bibbia',
                  options: { label: 'Bibbia'},
                  list: BibbiaList,
                  // create: PostCreate,
                  // edit: PostEdit,
                  // show: PostShow,
              },

          ]}
        />
      </RefineKbarProvider>
    </ColorModeContextProvider>
  );
}

export default App;
