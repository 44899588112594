import {
    IResourceComponentsProps,
    CrudFilters, getDefaultFilter, useCustom, useNotification, useList, useExport
} from '@pankod/refine-core';
import {
    List,
    Table,
    Select,
    Col,
    Row,
    ExportButton,
    Input,
    useSelect,
    Button,
    DateField,
    Space,
    notification,
    useTable,
    Divider,
    Typography, Icons, Switch, Checkbox, SelectProps, Upload, UploadProps, message,
} from '@pankod/refine-antd'
import React, {useEffect, useState} from 'react';
import {axiosInstance} from '../../provider';
import {API_URL} from '../../constants';
import FileSaver from 'file-saver';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';
const {Title} = Typography;

export const BibbiaList: React.FC<IResourceComponentsProps> = () => {

    const onSelectClienti = (e: any) => {
        console.log(e);
        setCodCliente(e);
    }

    const onSelectRefCliente = (e: any) => {
        console.log(e);
        setRefCliente(e);
    }

    const onSelectCodart = (e: any) => {
        console.log(e);
        setCodart(e);
    }

    const onSelectWbs = (e: any) => {
        console.log(e);
        setCodWbs(e);
    }

    const onSelectBudget = (e: any) => {
        console.log(e);
        setCodBudget(e);
    }

    const onSelectDivisione = (e: any) => {
        console.log(e);
        setCodDivisione(e);
    }

    const onSelectType = (e: any) => {
        console.log(e);
        setDaDove(e);
        setShowTable(false);
        setData([]);
    }

    const onClearSelectClienti = () => {
        setCodCliente('');
        setShowTable(false);
        setData([]);
    }

    const onClearSelectRefCliente = () => {
        setRefCliente('');
        setShowTable(false);
        setData([]);
    }

    const onClearSelectCodart = () => {
        setCodart('');
        setShowTable(false);
        setData([]);
    }

    const onClearSelectWbs = () => {
        setCodWbs('');
        setShowTable(false);
        setData([]);
    }

    const onClearSelectBudget = () => {
        setCodBudget('');
        setShowTable(false);
        setData([]);
    }

    const onClearSelectDivisione = () => {
        setCodDivisione('');
        setShowTable(false);
        setData([]);
    }

    const onClearSelectType = () => {
        setDaDove('');
        setShowTable(false);
        setData([]);
    }

    const onClearDaPianificare = (e: CheckboxChangeEvent) => {
        console.log(e.target.checked ? 1 : 0);
        setIsDaPianificare(e.target.checked ? 1 : 0);
        setShowTable(false);
        setData([]);
    }

    const onClearFinit = (e: CheckboxChangeEvent) => {
        console.log(e.target.checked ? 1 : 0);
        setIsFinit(e.target.checked ? 1 : 0);
        setShowTable(false);
        setData([]);
    }

    const onLoadJson = (e: CheckboxChangeEvent) => {
        console.log(e.target.checked ? 1 : 0);
        setIsLoadJson(e.target.checked ? 1 : 0);
        setShowTable(false);
        setData([]);
    }



    const [codCliente, setCodCliente] = useState('');
    const [refCliente, setRefCliente] = useState('');
    const [codCodart, setCodart] = useState('');
    const [codWbs, setCodWbs] = useState('');
    const [codBudget, setCodBudget] = useState('');
    const [codDivisione, setCodDivisione] = useState('');
    const [daDove, setDaDove] = useState('');
    const [isDaPianificare, setIsDaPianificare] = useState(0);
    const [isFinit, setIsFinit] = useState(0);
    const [isLoadJson, setIsLoadJson] = useState(0);

    const {selectProps: clienti} = useSelect({
        resource: 'elencoClienti',
        optionValue: 'id',
        optionLabel: 'label'
    });

    const {selectProps: refClienti} = useSelect({
        resource: 'elencoRefClienti',
        optionValue: 'id',
        optionLabel: 'label'
    });

    const {selectProps: codart} = useSelect({
        resource: 'elencoCodart',
        optionValue: 'id',
        optionLabel: 'label'
    });

    const {selectProps: wbs} = useSelect({
        resource: 'elencoWbs',
        optionValue: 'id',
        optionLabel: 'label'
    });

    const {selectProps: budget} = useSelect({
        resource: 'elencoMeseBudget',
        optionValue: 'id',
        optionLabel: 'label'
    });

    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [data, setData] = useState([]);

    const confirm = () => {
        // if (codBudget) {
            setLoading(true);
            setShowTable(false);
            axiosInstance.get(API_URL + '/api/getSbibbia?codCliente=' + codCliente +
                '&refCliente=' + refCliente +
                '&codart=' + codCodart +
                '&wbs=' + codWbs +
                '&budget=' + codBudget +
                '&daDove=' + daDove +
                '&divisione=' + codDivisione +
                '&isDaPianificare=' + isDaPianificare +
                '&isFinit=' + isFinit +
                '&isLoadJson=' + isLoadJson, {
            }).then( (result: any) => {
                console.log(result);
                setData(result.data.value);
                axiosInstance.get(API_URL + '/api/sbibbia?codCliente=' + codCliente +
                    '&refCliente=' + refCliente +
                    '&codart=' + codCodart +
                    '&wbs=' + codWbs +
                    '&budget=' + codBudget +
                    '&daDove=' + daDove +
                    '&divisione=' + codDivisione +
                    '&isDaPianificare=' + isDaPianificare +
                    '&isFinit=' + isFinit +
                    '&isLoadJson=' + isLoadJson,{
                    responseType: 'blob', // had to add this one here
                }).then( (res: any) => {
                    // console.log(res);
                    setShowTable(true);
                    if (res) {
                        const file = new Blob([res.data], { type: "application/pdf" });
                        //window.open(window.URL.createObjectURL(file));
                        const d = new Date().toISOString().replace('T', '_').replace('Z', '').toString();
                        FileSaver.saveAs(file, 'Bibbia_' + d.split('.')[0] + '.pdf');

                    } else {
                        notification.open({message: 'Nessun Dato'})
                    }
                    setLoading(false);
                })
            })
        // } else {
        //     notification.open({message: 'Devi selezionare almeno il Mese Budget!'})
        // }

    }

    const getFile = () => {
        axiosInstance.get(API_URL + '/api/fileEsempio',{
            responseType: 'arraybuffer', // had to add this one here
        }).then( (res: any) => {
            console.log(res.data);
            if (res) {
                const file = window.URL.createObjectURL(new Blob([res.data]));
                // window.open(window.URL.createObjectURL(file));
                FileSaver.saveAs(file, 'File_Esempio.xlsx');

            } else {
                notification.open({message: 'Nessun Dato'})
            }
        })
    }

    const props: UploadProps = {
        name: 'file',
        action: API_URL + '/api/uploadDocument',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                axiosInstance.get(API_URL + '/api/callFromExcel2Json?excelFileName=./storage/' + info.file.name + '&jsonFileName=./storage/codPerBibbia.json&apiCall=', {
                }).then( (res: any) => {
                    console.log(res);
                    setIsLoadJson(1);
                    setShowTable(false);
                    setData([]);
                })
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <>
                        <List title='Estrazione Bibbia'
                              headerProps={{
                                  extra: [],
                              }}
                        >
                            <Row>
                                <Col xs={24} xl={6} md={6} sm={8}>
                                    <Select {...clienti}
                                            style={{width: '90%', marginBottom: 20}}
                                            allowClear={true}
                                            placeholder="Seleziona Cliente..."
                                            onSelect={(e) => onSelectClienti(e)}
                                            onClear={onClearSelectClienti}
                                            optionFilterProp="value"
                                            showSearch
                                            filterOption={(inputValue, option: any) => {
                                                return option.label.toLowerCase().includes(inputValue.toLowerCase())
                                            }
                                            }
                                    />
                                </Col>
                                <Col xs={24} xl={6} md={6} sm={8}>
                                    <Select {...refClienti}
                                            style={{ width: '90%', marginBottom: 20}}
                                            allowClear={true}
                                            placeholder="Seleziona Ref Cliente..."
                                            onSelect={(e) => onSelectRefCliente(e)}
                                            onClear={onClearSelectRefCliente}
                                            optionFilterProp="value"
                                            showSearch
                                            filterOption={(inputValue, option: any) => {
                                                return option.label.toLowerCase().includes(inputValue.toLowerCase())
                                            }
                                            }
                                    />
                                </Col>
                                <Col xs={24} xl={6} md={6} sm={8}>
                                    <Select {...codart}
                                            style={{ width: '90%', marginBottom: 20}}
                                            allowClear={true}
                                            placeholder="Seleziona Articolo..."
                                            onSelect={(e) => onSelectCodart(e)}
                                            onClear={onClearSelectCodart}
                                            filterOption={(inputValue, option: any) => {
                                                return option.label.toLowerCase().includes(inputValue.toLowerCase())
                                            }
                                            }
                                    />
                                </Col>
                                <Col xs={24} xl={6} md={6} sm={8}>
                                    <Select {...wbs}
                                            style={{ width: '90%', marginBottom: 20}}
                                            allowClear={true}
                                            placeholder="Seleziona Wbs..."
                                            onSelect={(e) => onSelectWbs(e)}
                                            onClear={onClearSelectWbs}
                                            filterOption={(inputValue, option: any) => {
                                                return option.label.toLowerCase().includes(inputValue.toLowerCase())
                                            }
                                            }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} xl={4} md={4} sm={8}>
                                    <Select style={{ width: '90%', marginBottom: 20}}
                                            allowClear={true}
                                            placeholder="Seleziona Da Dove..."
                                            onSelect={(e) => onSelectType(e)}
                                            onClear={onClearSelectType}
                                    >
                                        <Select.Option key={'ACQ'} value={'ACQ'}>
                                            {'ACQUISTI'}
                                        </Select.Option>
                                        <Select.Option key={'ODP'} value={'ODP'}>
                                            {'ODP'}
                                        </Select.Option>
                                        <Select.Option key={'TUT'} value={'TUT'}>
                                            {'TUTTI'}
                                        </Select.Option>
                                    </Select>
                                </Col>
                                <Col xs={24} xl={4} md={4} sm={8}>
                                    <Select {...budget}
                                            style={{ width: '90%', marginBottom: 20}}
                                            allowClear={true}
                                            placeholder="Seleziona Mese Budget..."
                                            onSelect={(e) => onSelectBudget(e)}
                                            onClear={onClearSelectBudget}
                                    />
                                </Col>
                                <Col xs={24} xl={4} md={4} sm={8}>
                                    <Select
                                            style={{ width: '90%', marginBottom: 20}}
                                            allowClear={true}
                                            placeholder="Seleziona Divisione..."
                                            onSelect={(e) => onSelectDivisione(e)}
                                            onClear={onClearSelectDivisione}
                                    >
                                        <Select.Option key={'TUTTO'} value={'TUTTO'}>
                                            {'TUTTO'}
                                        </Select.Option>
                                        <Select.Option key={'MAGAZZINO DIVISIONE 1'} value={'MAGAZZINO DIVISIONE 1'}>
                                            {'DIVISIONE 1'}
                                        </Select.Option>
                                        <Select.Option key={'MAGAZZINO DIVISIONE 2'} value={'MAGAZZINO DIVISIONE 2'}>
                                            {'DIVISIONE 2'}
                                        </Select.Option>
                                    </Select>
                                </Col>
                                <Col xs={24} xl={6} md={6} sm={8}>
                                    <Row>
                                        <Col>
                                            <Checkbox style={{ width: '300px', marginBottom: 20}}
                                                      onChange={onClearDaPianificare}>Comprendi anche "DA PIANIFICARE"</Checkbox>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} xl={6} md={6} sm={8}>
                                    <Col>
                                        <Checkbox style={{ width: '300px', marginBottom: 20}}
                                                  onChange={onClearFinit}>Comprendi anche "FINIT"</Checkbox>
                                    </Col>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                        {/*<Checkbox style={{ width: '300px', marginBottom: 20}}*/}
                                        {/*          onChange={onLoadJson}>Carica da File JSON</Checkbox>*/}
                                        <Upload {...props}>
                                            <Button icon={<Icons.UploadOutlined />}>Carica da File XLS</Button>
                                        </Upload>
                                </Col>
                                <Col span={8}>
                                        <Button icon={<Icons.DownloadOutlined />} onClick={getFile}>Scarica Esempio</Button>
                                </Col>
                                <Col span={8}>
                                    <Button type={'primary'} loading={loading} style={{ width: '90%'}} onClick={confirm}>Conferma</Button>
                                </Col>
                            </Row>
                            <br />
                            <Row gutter={24}>
                                <Col span={24}>
                                    <div style={{textAlign: 'center'}}>
                                        <Title level={5}
                                               style={{ fontStyle: 'italic', fontWeight: 200}}>
                                            I campi non sono autocorrelati pertanto impostare filtri coerenti. La condizione "DA PIANIFICARE" non agisce sul
                                            cliente.
                                        </Title>
                                    </div>

                                </Col>
                                <Col span={24} hidden={!loading}>
                                    <Divider></Divider>
                                    <Title level={5}
                                           style={{ fontStyle: 'italic', fontWeight: 200}}>
                                        Totale Record: <span style={{
                                        color: 'red',
                                        fontWeight: 600
                                    }}>{data.length}</span> - Inizio Elaborazione ore <span style={{
                                        color: 'red',
                                        fontWeight: 600
                                    }}>{dayjs().format('HH:mm')}</span> - Tempo di attesa stimato: <span style={{
                                        color: 'red',
                                        fontWeight: 600
                                    }}> { Math.ceil(data.length/100) + 1}</span> minuti - <span style={{
                                        color: 'red',
                                        fontWeight: 400
                                    }}> Attendere senza aggiornare la pagina...</span> <Icons.CoffeeOutlined
                                        style={{ color: 'green', fontSize: 30, animation: 'ease-in-out'}}
                                    /> <span style={{
                                        color: 'red',
                                        fontWeight: 400
                                    }}>puoi cmq continuare a navigare.</span>
                                    </Title>
                                </Col>
                                <Col span={24} hidden={!loading}>
                                    <Table dataSource={data} >
                                        <Table.Column
                                            dataIndex={'U_meseBudget'}
                                            key={'U_meseBudget'}
                                            title={'U_meseBudget'}
                                            />
                                        <Table.Column
                                            dataIndex={'U_rifCliente'}
                                            key={'U_rifCliente'}
                                            title={'U_rifCliente'}
                                        />
                                        <Table.Column
                                            dataIndex={'U_codArtCli'}
                                            key={'U_codArtCli'}
                                            title={'U_codArtCli'}
                                        />
                                        <Table.Column
                                            dataIndex={'U_daDove'}
                                            key={'U_daDove'}
                                            title={'U_daDove'}
                                        />
                                        <Table.Column
                                            dataIndex={'U_docNum'}
                                            key={'U_docNum'}
                                            title={'U_docNum'}
                                        />
                                        <Table.Column
                                            dataIndex={'U_codArt'}
                                            key={'U_codArt'}
                                            title={'U_codArt'}
                                        />
                                        <Table.Column
                                            dataIndex={'U_fornitore'}
                                            key={'U_fornitore'}
                                            title={'U_fornitore'}
                                        />
                                    </Table>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24} hidden={!showTable}>
                                    <div style={{textAlign: 'center'}}>
                                        <Title level={3} style={{
                                            color: 'yellowgreen',
                                            fontWeight: 200
                                        }}>I dati sono Pronti...Buona Lettura!</Title>
                                    </div>

                                </Col>
                            </Row>
                        </List>
        </>
    );
};
