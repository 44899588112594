import {
    IResourceComponentsProps,
    CrudFilters, getDefaultFilter, useCustom, useNotification
} from '@pankod/refine-core';
import {
    List,
    Table,
    TextField,
    useTable,
    Icons,
    getDefaultSortOrder,
    DateField,
    TagField,
    Select,
    Col, Row, Form, Input, Button, Card, DatePicker, Popover, useSelect,
} from '@pankod/refine-antd'
import React, {useMemo, useState} from "react";
import dayjs from "dayjs";
import {axiosInstance} from '../../provider';

export const ZZlabelList: React.FC<IResourceComponentsProps> = () => {
    const [print, setPrint] = useState<any>();
    const { open, close } = useNotification();
    const {tableProps, sorter, searchFormProps, filters, tableQueryResult} = useTable<any>({
        resource: "zzlabel",
        initialSorter: [
            {
                field: "numOdp",
                order: "asc",
            },
        ],
        onSearch: (params: any) => {
            console.log(params);
            const filters: CrudFilters = [];
            const {numOdp} = params;
            filters.push(
                {
                    field: "numOdp",
                    operator: "contains",
                    value: numOdp,
                }
            );
            return filters;
        },
    });

    const [title, setTitle] = useState('');

    const { data } = useCustom<any>({
        url: "getAllPrinters",
        method: "get"
    });

    console.log(data?.data);
    function padDigits(n: number, digits = 3) {
        return Array(Math.max(digits - String(n).length + 1, 0)).join('0') + n;
    }
    const stampaEtichetta = (record: any) => {
        console.log(record);
        console.log(print)
        if (print) {
            if (record.U_ZZ_Prog_Trasm === null) record.U_ZZ_Prog_Trasm = String(1);
            for (let q = Number(record.U_ZZ_Prog_Trasm); q < (Number(record.U_Quantity) + Number(record.U_ZZ_Prog_Trasm)); q++) {
                const numOdp = record.numOdp.toString().substr(-6);
                const itemCode = record.ItemCode;
                const pad = padDigits(q);
                const U_ZZ_Ord_Cliente = record.U_ZZ_Ord_Cliente;
                const date = new Date().getFullYear().toString();
                const U_ZZ_Tipo_Mont = record.U_ZZ_Tipo_Mont;
                const U_ZZ_Rapporto = record.U_ZZ_Rapporto;
                const U_ZZ_Tipo_Lubrif = record.U_ZZ_Tipo_Lubrif;
                const U_ZZ_Codice_Cliente = record.U_ZZ_Codice_Cliente;

                // ESEGUO LA STAMPA ETICHETTE
                axiosInstance.post('/api/printEtichetta/' + print + '/ZZlabel', {
                    numOdp,
                    itemCode,
                    pad,
                    U_ZZ_Ord_Cliente,
                    date,
                    U_ZZ_Tipo_Mont,
                    U_ZZ_Rapporto,
                    U_ZZ_Tipo_Lubrif,
                    U_ZZ_Codice_Cliente
                })
            }
        } else {
            open?.({
                type: "error",
                message: "Devi Selezionare la Stampante",
                description: "Attenzione!",
            });
        }
    }

    const selectPrinter = (record: any) => {
        console.log(record);
        setPrint(record);
    }

    return (
        <>
                        <List title='ZZLabel' headerButtons={
                            <Form layout="horizontal" {...searchFormProps}>
                                <Row>
                                    <Col style={{ marginRight: 20}}>
                                        <Form.Item
                                            label="Sleziona Stampante"
                                            name="selectPrint"
                                        >
                                            <Select
                                                style={{ width: 200}}
                                                allowClear={true}
                                                placeholder="Seleziona Stampante..."
                                                onClear={() => setPrint(null)}
                                                onSelect={(e) => selectPrinter(e)}
                                            >
                                                { data?.data?.data?.filter((x: any) => x.isLabelPrinter === true).map((option: any) => {
                                                    console.log(option)
                                                    return(
                                                        <Select.Option key={option.code} value={option.code}>
                                                            {option.name}
                                                        </Select.Option>
                                                    )})
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ marginRight: 20}}>
                                            <Form.Item label="Numero ODP" name="numOdp">
                                                <Input
                                                    allowClear={true}
                                                    placeholder="Odp"
                                                    prefix={<Icons.SearchOutlined/>}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                />
                                            </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item>
                                            <Button htmlType="submit" type="primary">
                                                Cerca
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        }>
                            <Table {...tableProps} rowKey="id" >
                                <Table.Column
                                    dataIndex="CardName"
                                    key="CardName"
                                    title='Cliente'
                                    render={(value) => <TextField value={value}/>}
                                />
                                <Table.Column
                                    dataIndex="Warehouse"
                                    key="Warehouse"
                                    title="Magazzino"
                                    render={(value) => <TextField value={value}/>}
                                />
                                <Table.Column
                                    dataIndex="U_Quantity"
                                    key="U_Quantity"
                                    title="Quantità"
                                    render={(value) => <TextField value={value}/>}
                                />
                                <Table.Column
                                    dataIndex="U_ZZ_Codice_Cliente"
                                    key="U_ZZ_Codice_Cliente"
                                    title="Cod_Cliente"
                                    render={(value) => <TextField value={value}/>}
                                />
                                <Table.Column
                                    dataIndex="U_ZZ_Ord_Cliente"
                                    key="U_ZZ_Ord_Cliente"
                                    title="Ord_Cliente"
                                    render={(value) => <TextField value={value}/>}
                                />
                                <Table.Column
                                    dataIndex="U_ZZ_Prog_Trasm"
                                    key="U_ZZ_Prog_Trasm"
                                    title="Prog_Trasm"
                                    render={(value) => <TextField value={value}/>}
                                />
                                <Table.Column
                                    dataIndex="U_ZZ_Rapporto"
                                    key="U_ZZ_Rapporto"
                                    title="Rapporto"
                                    render={(value) => <TextField value={value}/>}
                                />
                                <Table.Column
                                    dataIndex="U_ZZ_Tipo_Lubrif"
                                    key="U_ZZ_Tipo_Lubrif"
                                    title="Tipo_Lubrif"
                                    render={(value) => <TextField value={value}/>}
                                />
                                <Table.Column
                                    dataIndex="U_ZZ_Tipo_Mont"
                                    key="U_ZZ_Tipo_Mont"
                                    title="Tipo_Mont"
                                    render={(value) => <TextField value={value}/>}
                                />
                                <Table.Column
                                    dataIndex="actions"
                                    key="actions"
                                    title="Action"
                                    render={((_, record) => <Button onClick={() => stampaEtichetta(record)}> Stampa Etichetta</Button>)}
                                />
                            </Table>
                        </List>
        </>
    );
};
