import {
    Button,
    Col,
    DateField,
    Icons, ListButton,
    Modal, notification, Popover, Radio, RadioChangeEvent, RefreshButton,
    Row,
    Show,
    ShowButton,
    Space, Spin,
    Table,
    Typography, useModal,
    useModalForm
} from '@pankod/refine-antd';
import React, {useState} from 'react';
import {IResourceComponentsProps, useCustom, useList, useSelect, useShow} from '@pankod/refine-core';
import {IProductOrder} from '../../interfaces';
import {axiosInstance} from '../../provider';
import {API_URL} from '../../constants';
const { Title } = Typography;
const PDFMerger = require('pdf-merger-js');
export const OdpShow: React.FC<IResourceComponentsProps> = () => {

    const {showId, setShowId, queryResult} = useShow<IProductOrder>({});
    const { modalProps, show } = useModal({});
    const [docEntry, setDocEntry] = useState();
    const [lineNum, setLineNum] = useState();
    const [dataDetails, setDataDetails] = useState();
    const [loading, setLoading] = useState(false);

    const details = useCustom({
        url: "proxy/SQLQueries('lsMovimentazioni')/List?U_DocEntry=" + docEntry + "&U_LineNum=" + lineNum,
        method: "get",
        queryOptions: {
            enabled: false
        },

    })
    const getDetail = (record: any) => {
        console.log(record);
        setDocEntry(record.DocumentAbsoluteEntry);
        setLineNum(record.LineNumber);
        setTimeout(() => {
            details.refetch().then( (res: any) => {
                console.log(res);
                setDataDetails(res.data?.data?.value);
                show();
            })
        }, 500)

    }

    const stampaBibbia = async () => {
        const merger = new PDFMerger();
        console.log(showId);
        setLoading(true);
        axiosInstance.get(API_URL + '/api/bibbia?docNum=' + queryResult.data?.data.DocumentNumber + '&itemCode=' + queryResult.data?.data.ItemNo + '&typePrint=' + queryResult.data?.data.ItemNo, {
            responseType: 'blob', // had to add this one here
        }).then( res => {
            console.log(res);
            const file = new Blob([res.data], { type: "application/pdf" });
            window.open(window.URL.createObjectURL(file))
            setLoading(false);
        })
    }

    const [clicked, setClicked] = useState(false);
    const handleClickChange = (open: boolean) => {
        setClicked(open);
    };

    const [value, setValue] = useState('');
    const onChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
        const merger = new PDFMerger();
        console.log(showId);
        setLoading(true);
        setClicked(false);
        axiosInstance.get(API_URL + '/api/bibbia?docNum=' + queryResult.data?.data.DocumentNumber + '&itemCode=' + queryResult.data?.data.ItemNo + '&typePrint=' + e.target.value, {
            responseType: 'blob', // had to add this one here
        }).then( res => {
            console.log(res);
            if (res) {
                const file = new Blob([res.data], { type: "application/pdf" });
                window.open(window.URL.createObjectURL(file))
            } else {
                notification.open({message: 'Nessun Dato'})
            }
            setLoading(false);
        })
    };

    return (
        <Show title="ODP Detail"
              headerButtons={
                        <>
                            <ListButton />
                            <Popover placement="bottom" content={
                                <Radio.Group
                                    onChange={onChange} value={value}>
                                <Radio value={'bibbia'}>Bibbia</Radio>
                                <Radio value={'missed'}>Mancanti</Radio>
                                <Radio value={'itemcode'}>Articolo</Radio>
                            </Radio.Group>} title="Seleziona Tipo" trigger={'click'} open={clicked} onOpenChange={handleClickChange}>
                                <Button loading={loading}>Stampa Bibbia</Button>
                            </Popover>

                            <RefreshButton />
                        </>

        }>
            <Row gutter={24}>
                <Col span={8}>
                    <Title level={5}>DocumentNumber: {queryResult.data?.data.DocumentNumber}</Title>
                </Col>
                <Col span={8}>
                    <Title level={5}>DueDate: <DateField value={queryResult.data?.data.DueDate} /></Title>
                </Col>
                <Col span={8}>
                    <Title level={5}>CreationDate: <DateField value={queryResult.data?.data.CreationDate} /></Title>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Title level={5}>ItemCode: {queryResult.data?.data.ItemNo}</Title>
                </Col>
                <Col span={8}>
                    <Title level={5}>ProductDescription: {queryResult.data?.data.ProductDescription}</Title>
                </Col>
                <Col span={8}>
                    <Title level={5}>ProductionOrderStatus: {queryResult.data?.data.ProductionOrderStatus}</Title>
                </Col>
            </Row>
            <br />
            <Row gutter={24}>
                <Col span={24}>
                    <Table dataSource={queryResult.data?.data.ProductionOrderLines}>
                        <Table.Column
                            dataIndex={"VisualOrder"}
                            key={"VisualOrder"}
                            title={"VisualOrder"}
                        />
                        <Table.Column
                            dataIndex={"ItemNo"}
                            key={"ItemNo"}
                            title={"ItemNo"}
                        />
                        <Table.Column
                            dataIndex={"ItemName"}
                            key={"ItemName"}
                            title={"ItemName"}
                        />
                        <Table.Column
                            dataIndex={"BaseQuantity"}
                            key={"BaseQuantity"}
                            title={"BaseQuantity"}
                        />
                        <Table.Column
                            dataIndex={"PlannedQuantity"}
                            key={"PlannedQuantity"}
                            title={"PlannedQuantity"}
                        />
                        <Table.Column
                            dataIndex={"IssuedQuantity"}
                            key={"IssuedQuantity"}
                            title={"IssuedQuantity"}
                        />
                        <Table.Column
                            dataIndex={"Warehouse"}
                            key={"Warehouse"}
                            title={"Warehouse"}
                        />
                        <Table.Column
                            dataIndex={"StartDate"}
                            key={"StartDate"}
                            title={"StartDate"}
                            render={(value, record, index) => <DateField value={value} />}
                        />
                        <Table.Column
                            dataIndex={"EndDate"}
                            key={"EndDate"}
                            title={"EndDate"}
                            render={(value, record, index) => <DateField value={value} />}
                        />
                        <Table.Column<IProductOrder>
                            dataIndex="Action"
                            key="action"
                            title="Azioni"
                            render={(_text, record): React.ReactNode => {
                                return (
                                    <Space>
                                        <Button icon={<Icons.SearchOutlined />}
                                                    onClick={() => getDetail(record)}
                                        />
                                        <Modal {...modalProps} width={"100%"}>
                                                <Table dataSource={dataDetails}>
                                                    <Table.Column
                                                        dataIndex="U_Data"
                                                        key="U_Data"
                                                        title="U_Data"
                                                        />
                                                    <Table.Column
                                                        dataIndex="U_NomeCausale"
                                                        key="U_NomeCausale"
                                                        title="U_NomeCausale"
                                                    />
                                                    <Table.Column
                                                        dataIndex="U_NomeMacchina"
                                                        key="U_NomeMacchina"
                                                        title="U_NomeMacchina"
                                                    />
                                                    <Table.Column
                                                        dataIndex="U_NomeOperatore"
                                                        key="U_NomeOperatore"
                                                        title="U_NomeOperatore"
                                                    />
                                                    <Table.Column
                                                        dataIndex="U_nomeAStato"
                                                        key="U_nomeAStato"
                                                        title="U_nomeAStato"
                                                    />
                                                    <Table.Column
                                                        dataIndex="U_nomeDaStato"
                                                        key="U_nomeDaStato"
                                                        title="U_nomeDaStato"
                                                    />
                                                    <Table.Column
                                                        dataIndex="U_nomeMotivazione"
                                                        key="U_nomeMotivazione"
                                                        title="U_nomeMotivazione"
                                                    />
                                                    <Table.Column
                                                        dataIndex="U_voceMenu"
                                                        key="U_voceMenu"
                                                        title="U_voceMenu"
                                                    />
                                                </Table>
                                        </Modal>
                                    </Space>
                                );
                            }}
                        />
                    </Table>
                </Col>
            </Row>
        </Show>
    );
};
