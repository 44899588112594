import {AxiosInstance} from "axios";
import {stringify} from "query-string";
import {DataProvider} from "@pankod/refine-core";
import {axiosInstance, generateSort, generateFilter} from "./utils";

export const myDataProvider = (
    apiUrl: string,
    httpClient: AxiosInstance = axiosInstance,
): Omit<
    Required<DataProvider>,
    "createMany" | "updateMany" | "deleteMany"
> => ({
    getList: async ({
                        resource,
                        hasPagination = true,
                        pagination = {current: 1, pageSize: 10},
                        filters,
                        sort,
                    }) => {
        const url = `${apiUrl}/${resource}`;

        const {current = 1, pageSize = 10} = pagination ?? {};

        const queryFilters = generateFilter(filters);

        const query: any = {};
        if (hasPagination) {
            query['$count'] = true;
            query['$top'] = pageSize;
            query['$skip'] = ((current - 1) * pageSize);
        }

        const generatedSort = generateSort(sort);
        if (generatedSort) {
            const {_sort, _order} = generatedSort;
            // console.log(`SORT: ${_sort.length} ORDER: ${_order.length}`);
            let queryString = '';
            for (let i = 0; i < _sort.length; i++) {
                queryString += `${_sort[i]} ${_order[i]}`;
                if (i < _sort.length - 1) {
                    queryString += ',';
                }
            }
            query['$orderby'] = queryString;
        }

        const finalUrl: string = `${url}?${stringify(query)}&${queryFilters}`;
        const encoded = decodeURI(finalUrl);
        console.log(`URL CHIAMATO:`, encoded);
        const {data, headers} = await httpClient.get(finalUrl);

        // console.log(`FROM PROVIDER ${data}`);

        return {
            data: data['value'],
            total: data['@odata.count'],
        };
    },

    getMany: async ({resource, ids}) => {
        console.log('.........ATTENZIONE getMany METODO NON GESTITO.........');
        const {data} = await httpClient.get(
            `${apiUrl}/${resource}?${stringify({id: ids})}`);

        return {
            data: data['value'],
            total: data['total'],
        };
    },

    create: async ({resource, variables}) => {
        const url = `${apiUrl}/${resource}`;

        const {data} = await httpClient.post(url, variables);

        return {
            data,
        };
    },

    update: async ({resource, id, variables}) => {
        const url = `${apiUrl}/${resource}(${id})`;

        const {data} = await httpClient.patch(url, variables);

        return {
            data,
        };
    },

    getOne: async ({resource, id}) => {
        const url = `${apiUrl}/${resource}(${id})`;

        const {data} = await httpClient.get(url);

        return {
            data,
        };
    },

    deleteOne: async ({resource, id, variables}) => {
        const url = `${apiUrl}/${resource}(${id})`;

        const {data} = await httpClient.delete(url, {
            data: variables,
        });

        return {
            data,
        };
    },

    getApiUrl: () => {
        return apiUrl;
    },

    custom: async ({url, method, filters, sort, payload, query, headers}) => {
        let requestUrl = `${apiUrl}/${url}`;

        if (sort) {
            const generatedSort = generateSort(sort);
            if (generatedSort) {
                const {_sort, _order} = generatedSort;
                // console.log(`SORT: ${_sort.length} ORDER: ${_order.length}`);
                let queryString = '';
                for (let i = 0; i < _sort.length; i++) {
                    queryString += `${_sort[i]} ${_order[i]}`;
                    if (i < _sort.length - 1) {
                        queryString += ',';
                    }
                }
                requestUrl = `${requestUrl}&${queryString}`;
            }
        }

        if (filters) {
            const filterQuery = generateFilter(filters);
            requestUrl = `${requestUrl}&${filterQuery}`;
        }

        if (query) {
            requestUrl = `${requestUrl}&${stringify(query)}`;
        }

        if (headers) {
            httpClient.defaults.headers = {
                ...httpClient.defaults.headers,
                ...headers,
            };
        }

        let axiosResponse;
        switch (method) {
            case "put":
            case "post":
            case "patch":
                axiosResponse = await httpClient[method](url, payload);
                break;
            case "delete":
                axiosResponse = await httpClient.delete(url, {
                    data: payload,
                });
                break;
            default:
                axiosResponse = await httpClient.get(requestUrl);
                break;
        }

        const {data} = axiosResponse;

        return Promise.resolve({data});
    },
});
