import {
    IResourceComponentsProps,
    CrudFilters, useCustom,
} from '@pankod/refine-core';
import {
    List,
    Table,
    TextField,
    useTable,
    Icons,
    getDefaultSortOrder,
    DateField,
    Col, Row, Form, Input, Button, Card, Modal, useModal,
} from '@pankod/refine-antd';
import React, {useState} from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import {axiosInstance} from '../../provider';
import {API_URL} from '../../constants';

export const ItemList: React.FC<IResourceComponentsProps> = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = `pdf.worker.js`;
    const { show, modalProps } = useModal();
    const { show: showPdf, modalProps: pdfModal } = useModal();
    const {tableProps, sorter, searchFormProps, filters} = useTable<any>({
        initialSorter: [
            {
                field: "ItemName",
                order: "asc",
            },
        ],
        onSearch: (params: any) => {
            console.log(params);
            // setCategory(params.category);
            const filters: CrudFilters = [];
            const {ItemName, ItemCode} = params;
            filters.push(
                {
                    field: "ItemName",
                    operator: "contains",
                    value: ItemName,
                }
            );
            return filters;
        },
    });

    const [title, setTitle] = useState('');
    const [dataAttachment, setDataAttachment] = useState([]);
    const [streamAttachment, setStreamAttachment] = useState<any>();
    const [idAttachment, setIdAttachment]= useState('');
    const [nameAttachment, setNameAttachment]= useState('');
    const attachment = useCustom<any>({
        url: "/proxy/Attachments2(" + idAttachment + ")",
        method: "get",
        queryOptions: {
            enabled: false
        }
    });

    const downloadAttachment = useCustom<any>({
        url: "/proxy/Attachments2(" + idAttachment + ")/$value?filename='" + nameAttachment +"'",
        method: "get",
        queryOptions: {
            enabled: false
        }
    });

    const getAttachment = (id: any) => {
        setIdAttachment(id);
        setTimeout(() => {
            attachment.refetch().then( res => {
                console.log(res);
                setDataAttachment(res.data?.data?.Attachments2_Lines);
                show();
            })
        }, 1000)
        console.log(id);
    }

    const getDownloadAttachment = (record: any) => {
        setNameAttachment(record['FileName'] + "." + record['FileExtension']);
        axiosInstance.get(API_URL + "/api/proxy/Attachments2(" + idAttachment + ")/$value?filename='" + record['FileName'] + "." + record['FileExtension'] +"'", {
            responseType: 'blob', // had to add this one here
        }).then( res => {
            //Create a Blob from the PDF Stream
            const file = new Blob([res.data], { type: "application/pdf" });
            window.open(URL.createObjectURL(file))
        })
        console.log(record['FileName'] + "." + record['FileExtension']);
    }

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = (numPages: any) => {
        setNumPages(numPages);
    }

    return (
        <Row gutter={[16, 16]}>
            <Col lg={6} xs={24}>
                <Card bordered={false} title='FILTRI'>
                    <Form layout="vertical" {...searchFormProps}>
                        <Form.Item label="Descrizione Articolo" name="ItemName">
                            <Input
                                placeholder="Descrizione Articolo..."
                                prefix={<Icons.SearchOutlined/>}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">
                                Filtra
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>

            </Col>
            <Col lg={18} xs={24}>
                <Card bordered={false} title='Ordini di Produzione'>
                <List title=''>
                    <Table {...tableProps} rowKey="AbsoluteEntry" >
                        <Table.Column
                            dataIndex="ItemCode"
                            key="ItemCode"
                            title='Item Code'
                            sorter
                            defaultSortOrder={getDefaultSortOrder("ItemCode", sorter)}
                            render={(value) => <TextField value={value}/>}
                        />
                        <Table.Column
                            dataIndex="ItemName"
                            key="ItemName"
                            title='Item Name'
                            sorter
                            defaultSortOrder={getDefaultSortOrder("ItemName", sorter)}
                            render={(value) => <TextField value={value}/>}
                        />
                        <Table.Column
                            dataIndex="AttachmentEntry"
                            key="AttachmentEntry"
                            title='Item Files'
                            sorter
                            render={(value) => <>
                                {
                                    value && <Icons.FileSearchOutlined onClick={() => getAttachment(value)}/>
                                }
                                    <Modal {...modalProps}>
                                        <Table
                                            dataSource={dataAttachment}

                                        >
                                            <Table.Column
                                                dataIndex="AttachmentDate"
                                                key="AttachmentDate"
                                                title='Date'
                                                render={(value) => <DateField format="DD-MM-YYYY" value={value} />}
                                            />
                                            <Table.Column
                                                dataIndex="FileName"
                                                key="FileName"
                                                title='File Name'
                                            />
                                            <Table.Column
                                                dataIndex="action"
                                                key="action"
                                                title='Action'
                                                render={(value, record) => <>
                                                    <Icons.FolderViewOutlined onClick={() => getDownloadAttachment(record)}/>
                                                    <Modal {...pdfModal}>
                                                        <div style={{width: 300, height: 300}}>
                                                            <Document
                                                                file={{url: streamAttachment}}
                                                                onLoadSuccess={() => console.log('SUCCESS LOAD')}
                                                                options={{
                                                                    scale: 0.1
                                                                }}
                                                            >
                                                                <Page pageNumber={1} />
                                                            </Document>
                                                        </div>
                                                    </Modal>
                                                </>
                                                    }
                                            />
                                        </Table>
                                    </Modal>
                                </>}
                        />
                    </Table>
                </List>
                </Card>
            </Col>
        </Row>
    );
};
