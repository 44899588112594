import React from "react";
import {useLogin, useTranslate} from "@pankod/refine-core";
import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,
} from "@pankod/refine-antd";
import {ILoginForm} from "../../interfaces";
// import "./styles.css";
const { Title } = Typography;


export const Login: React.FC = () => {
    const translate = useTranslate();
    const [form] = Form.useForm<ILoginForm>();

    const { mutate: login } = useLogin<ILoginForm>();

    const CardTitle = (
        <Title level={3} className="title">
            Login
        </Title>
    );


    return (
        <AntdLayout className="layout">
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    <div className="container">
                        <div className="imageContainer">
                            <img width={200}
                                 src="images/logo.png"
                                 alt="LiSa" />
                        </div>
                        <Card style={{backgroundColor: 'rgba(51,51,51,0)', border: 'none'}}>
                            <Form<ILoginForm>
                                layout="vertical"
                                style={{ backgroundColor: 'rgba(51,51,51,0)', textAlign: 'center'}}
                                form={form}
                                onFinish={(values) => {
                                    login(values);
                                }}
                                requiredMark={false}
                            >
                                <Form.Item
                                    name="password"
                                    label='INSERISCI IL PIN UTILIZZATO NEI PANNELLI'
                                    rules={[
                                        {
                                            required: true ,
                                            max: 4,
                                            message: 'Il Pin non può essere superiore a 4 numeri'
                                        }]}
                                    style={{ marginBottom: "12px" }}
                                >
                                    <Input
                                        type="password"
                                        placeholder="●●●●"
                                        size="large"
                                    />
                                </Form.Item>
                                <div style={{ marginBottom: "12px" }}>
                                    <Form.Item
                                        name="remember"
                                        valuePropName="checked"
                                        noStyle
                                    >
                                        {/*<Checkbox*/}
                                        {/*    style={{*/}
                                        {/*        fontSize: "12px",*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    {translate('pages.login.buttons.rememberMe', 'Remember me')}*/}
                                        {/*</Checkbox>*/}
                                    </Form.Item>

                                    {/*<a*/}
                                    {/*    style={{*/}
                                    {/*        float: "right",*/}
                                    {/*        fontSize: "12px",*/}
                                    {/*    }}*/}
                                    {/*    href="/forgot-password"*/}
                                    {/*>*/}
                                    {/*    {translate('pages.login.buttons.forgotPassword', 'Forgot Password')}*/}
                                    {/*</a>*/}
                                </div>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    block
                                >
                                    {translate('pages.login.buttons.signin', 'ENTRA')}
                                </Button>
                            </Form>
                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
