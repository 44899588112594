import React from "react";
import {
    useTranslate, useUpdatePassword
} from "@pankod/refine-core";
import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
} from "@pankod/refine-antd";
import "./styles.css";
import {IUpdatePasswordFormTypes} from "../../interfaces";

const { Title} = Typography;

export const ResetPassword: React.FC = () => {
    const [form] = Form.useForm<IUpdatePasswordFormTypes>();
    const translate = useTranslate();
    const {mutate: updatePassword, isLoading} =
        useUpdatePassword<IUpdatePasswordFormTypes>();


    const CardTitle = (
        <Title level={3} className="title">
            {translate('pages.updatePassword.title', 'Forgot Password')}
        </Title>
    );


    return (
        <AntdLayout className="layout">
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    <div className="container">
                        <div className="imageContainer">
                            <img width={100} src="./logo200.png" alt="Logo"/>
                        </div>
                        <Card title={CardTitle} headStyle={{borderBottom: 0}}>
                            <Form<IUpdatePasswordFormTypes>
                                layout="vertical"
                                form={form}
                                onFinish={(values) => updatePassword(values)}
                                requiredMark={false}
                            >
                                <Form.Item
                                    name="code"
                                    label={translate(
                                        "pages.updatePassword.fields.code",
                                        "Code",
                                    )}
                                    rules={[{required: true}]}
                                    style={{marginBottom: "12px"}}
                                >
                                    <Input
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label={translate(
                                        "pages.updatePassword.fields.password",
                                        "New Password",
                                    )}
                                    rules={[{required: true}]}
                                    style={{marginBottom: "12px"}}
                                >
                                    <Input
                                        type="password"
                                        placeholder="●●●●●●●●"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="passwordConfirmation"
                                    label={translate(
                                        "pages.updatePassword.fields.confirmPassword",
                                        "Confirm New Password",
                                    )}
                                    hasFeedback
                                    dependencies={["password"]}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    getFieldValue("password") === value
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        translate(
                                                            "pages.updatePassword.errors.confirmPasswordNotMatch",
                                                            "Passwords do not match",
                                                        ),
                                                    ),
                                                );
                                            },
                                        }),
                                    ]}
                                    style={{marginBottom: "12px"}}
                                >
                                    <Input
                                        type="password"
                                        placeholder="●●●●●●●●"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        size="large"
                                        htmlType="submit"
                                        loading={isLoading}
                                        block
                                    >
                                        {translate(
                                            "pages.updatePassword.buttons.submit",
                                            "Update",
                                        )}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
