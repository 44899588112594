import {CrudFilters} from "@pankod/refine-core";
import {mapOperator} from "./mapOperator";
import {stringify} from "query-string";

export const generateFilter = (filters?: CrudFilters) => {
    let queryFilters: string = '';

    if (filters) {
        filters.map((filter) => {
            if (filter.operator === "or" || filter.operator === "and") {
                throw new Error(
                    `[@pankod/refine-simple-rest]: \`operator: ${filter.operator}\` is not supported. You can create custom data provider. https://refine.dev/docs/api-reference/core/providers/data-provider/#creating-a-data-provider`,
                );
            }

            if ("field" in filter) {
                const {field, operator, value} = filter;
                if (value.length === 0) {
                    return;
                }
                if (queryFilters.length > 0) {
                    queryFilters += ' and ';
                }
                if (operator === "contains") {
                    queryFilters += `contains(${field},${isNaN(value) ? '\'' + value + '\'' : '\'' + value + '\''})`;
                    return;
                }
                if (operator === 'in') {
                    console.log('TTTT', value);
                    let multipleValueString = '';
                    let i = 0;
                    for (const v of value) {
                        i++;
                        multipleValueString += `${field} eq '${v}'`
                        if (i < value.length) {
                            multipleValueString += ' or ';
                        }
                    }
                    queryFilters += multipleValueString;
                    return;
                }
                if(operator === 'gte'){
                    queryFilters += `${field} gt '${value}'`;
                }
                if(operator === 'lte'){
                    queryFilters += `${field} lt '${value}'`;
                }
                if(operator === 'eq'){
                    queryFilters += `${field} eq ${isNaN(value) ? '\'' + value + '\'' : value}`;
                }
            }
        });
    }
    return queryFilters.length > 0 ? `$filter=${queryFilters}` : '';
};
